import Link from 'next/link';
import * as React from 'react';
import logo from './assets/logo.svg'
import Image from 'next/image';

export interface ILogoProps {
  height: string;
  width: string;
}

export const Logo: React.FunctionComponent<ILogoProps> = ({ width, height }) => (
  <Link href="/">
    <a>
      <Image style={{ cursor: 'pointer' }} width={width} height={height} alt="Shelerz" src={logo.src} />
    </a>
  </Link>
);
