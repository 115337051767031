import LoginOutlined from '@ant-design/icons/LoginOutlined';
import Button from 'antd/lib/button';
import * as React from 'react';
import styles from './headerApp.module.scss';
import { Logo } from '../logo/logo';
import Link from 'next/link';
import { ROUTES } from '../../../routes/routes';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../store/store';
import { UserOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { logoutUserAction } from '../../store/user/actions/logoutUser';
import { COOKIE_ACCESS_TOKEN_KEY } from '../../../config';

export interface Props {
  type: 'main' | 'forms';
}
export const HeaderApp: React.FunctionComponent<Props> = ({ type }) => {
  const user = useSelector((store: IStore) => store.user);
  const dispatch = useDispatch();

  const menu = (
    <Menu
      style={{ minWidth: 200 }}
      items={[
        // {
        //   key: ROUTES.USER,
        //   label: 'Моя страница',
        // },
        // {
        //   key: ROUTES.OBJECT,
        //   label: 'Мои объекты',
        // },
        // {
        //   key: ROUTES.USER,
        //   label: <Link href={ROUTES.USER}>Моя страница</Link>,
        // },
        // {
        //   key: ROUTES.OBJECT,
        //   label: <Link href={ROUTES.OBJECT}>Мои объекты</Link>,
        // },
        {
          key: ROUTES.CHANGE_PASSWORD,
          label: <Link href={ROUTES.CHANGE_PASSWORD}>Сменить пароль</Link>,
        },
        {
          key: 'logout',
          label: 'Выйти',
          onClick: () => {
            dispatch(logoutUserAction());
            document.cookie = `${COOKIE_ACCESS_TOKEN_KEY}=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
          },
        },
      ]}
    />
  );

  return (
    <div className={styles.container}>
      <Logo width="125px" height="20px" />
      <div>
        {type === 'main' ? (
          user ? (
            <Dropdown trigger={['click']} placement="bottomRight" overlay={menu}>
              <Button
                style={{ background: '#00D1FF', borderRadius: '50%' }}
                size={'large'}
                icon={<UserOutlined style={{ color: '#0B0A3E' }} />}
                type="link"
              />
            </Dropdown>
          ) : (
            <Link href={ROUTES.SING_IN}>
              <Button size="large" icon={<UserOutlined style={{ color: '#00D1FF' }} />} type="link" />
            </Link>
          )
        ) : (
          <Button size="large" icon={<InfoCircleOutlined style={{ color: '#fff' }} />} type="link" />
        )}
      </div>
    </div>
  );
};
