export const ROUTES = {
  'SING_IN': '/sing-in',
  'ACCOUNT_RECOVERY': '/account-recovery',
  'CHANGE_PASSWORD': '/change-password',
  'USER': '/user',
  'OBJECT': '/object',
  'MAIN': '/',
  'REGISTER': '/register',
  'HOTEL_NEW': '/hotel/new',
  'HOTEL_EDIT_BASICS': '/hotel/edit/[id]/basics',
  'ORGANIZATIONS_ADD': '/organizations/add',
}
