export const CONFIG = {
  backend_server: {
    protocol: process.env.BACKEND_SERVER_PROTOCOL,
    host: process.env.BACKEND_SERVER_HOST,
    port: process.env.BACKEND_SERVER_PORT,
  },
  online_bnovo_server: {
    protocol: 'https',
    host: process.env.ONLINE_BNOVO_SERVER_HOST || 'online.bnovo.ru',
    port: process.env.ONLINE_BNOVO_SERVER_PORT || '443',
  },
  bnovo_server: {
    protocol: 'https',
    host: process.env.BNOVO_SERVER_HOST || 'api.reservationsteps.ru',
    port: process.env.BNOVO_SERVER_PORT || '443',
  },
  db_dialect: 'mysql',

  db_host: process.env.DB_HOST || '194.58.123.181',
  // db_name: process.env.DB_NAME || 'shelterz',
  db_password: process.env.DB_PASSWORD || 'jfL7481!',
  db_port: process.env.DB_PORT || '3306',
  db_user: process.env.DB_USER || 'shelterpartner',

  // db_port: process.env.DB_PORT || '3306',
  // db_user: process.env.DB_USER || 'root',
  // db_host: process.env.DB_HOST || 'localhost',
  db_hotels_rooms_name: process.env.DB_HOTELS_ROOMS_NAME || 'shelterz_rooms',
  db_hotels_name: process.env.DB_HOTELS_NAME || 'shelterz_hotels',
  db_references_name: process.env.DB_REFERENCES_NAME || 'shelterz_references',
  db_bookings_name: process.env.DB_NAME || 'shelterzio',
  db_name: process.env.DB_NAME || 'shelterz_managers',
  // db_password: process.env.DB_PASSWORD || 'root',

  tinkoff_password: process.env.TINKOFF_PASSWORD || '',

  bnovo_login: process.env.BNOVO_LOGIN || 'shelter.msk@gmail.com',
  db_pool: {
    acquire: 30000,
    idle: 10000,
    max: 5,
    min: 0,
  },
  bnovo_password: process.env.BNOVO_PASSWORD || 'tEdfqOw0aBioPtBaCyK2h9km2ECdMPsY',
  bnovo_accountid: process.env.BNOVO_ACCOUNT ? process.env.BNOVO_ACCOUNT.split(',') : ['618', '619', '4910', '9999', '11992'],
  env: process.env.APP || 'dev',
  jwt_encryption: process.env.JWT_ENCRYPTION || 'sdaskdamasdma',
  jwt_expiration: process.env.JWT_EXPIRATION || 24 * 60 * 60 * 1000,
  port: process.env.PORT || '3030',
  root_path: process.env.ROOT_PATH || '.',
  bnovo_credentials: {
    15814: {
      username: '6298c9f3bf2e9+15814@customapp.bnovo.ru',
      password: '84f8c65db4bfc67d',
    },
    13882: {
      username: '629dee5e77e78+13882@customapp.bnovo.ru',
      password: '872aa4aede16569e',
    },
    9060: {
      username: '629def4508dbf+9060@customapp.bnovo.ru',
      password: '96d8999641ce3dbd',
    },
    6417: {
      username: '629def6b04671+6417@customapp.bnovo.ru',
      password: '75876c783fd5df87',
    },
  },
};

export const COOKIE_ACCESS_TOKEN_KEY = 'auth_token';
