import {  createReducer } from '@reduxjs/toolkit'
import {initialState} from "../types/user.types";
import {setUserAction} from "../actions/setUser";
import {logoutUserAction} from "../actions/logoutUser";

export const userReducer = createReducer(initialState, (builder) => {
  builder.addCase(setUserAction, (state, action) => {
    return action.payload;
  });

  builder.addCase(logoutUserAction, () => {
    return null;
  });
});
