import {
  applyMiddleware,
  combineReducers,
  AnyAction,
  createStore
} from '@reduxjs/toolkit'
import {userReducer} from "./user/reducer";
import { HYDRATE, createWrapper } from 'next-redux-wrapper';
import { IUserModel } from "../../models/user";

export interface IStore {
  user: IUserModel | null;
}

const bindMiddleware = (middleware: []) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension')
    return composeWithDevTools(applyMiddleware(...middleware))
  }
  return applyMiddleware(...middleware)
}

const combinedReducer = combineReducers({
  user: userReducer
})

const reducer = (state: IStore | undefined, action: AnyAction) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    }

    return nextState
  } else {
    return combinedReducer(state, action);
  }
}

const initStore = () => {
  return createStore(reducer, bindMiddleware([]));
}

export const storeWrapper = createWrapper(initStore)
