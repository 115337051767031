import { HeaderApp } from "../../headerApp/headerApp";
import * as React from "react";
import Layout, { Content, Footer, Header } from "antd/lib/layout/layout";
import styles from './MainLayout.module.scss'

interface Props {
  children: React.ReactNode
  typeHeader: 'main' | 'forms'
}

export const MainLayout: React.FunctionComponent<Props> = ({ children, typeHeader }) => {
  return (
    <Layout style={{ background: '#fff', minHeight: '100vh' }} className="layout">
      <Header className={typeHeader === 'main' ? `${styles.header} ${styles.main}` : styles.header}>
        <HeaderApp type={typeHeader} />
      </Header>
      <Content>
        {children}
      </Content>
      <Footer style={{ textAlign: 'center' }}>Shelterz.ru</Footer>
    </Layout >
  )
}
