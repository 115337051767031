import { ConfigProvider } from 'antd';
import ruRu from 'antd/lib/locale/ru_RU';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { storeWrapper } from '../app/store/store';
import queryClientConfig from '../app/configs/queryClientConfig'
import '../styles/global.css';
import "../styles/antd.less"
import { Dispatch, ReactElement, ReactNode, SetStateAction, createContext, useState } from 'react';
import type { AppProps } from 'next/app'
import type { NextPage } from 'next'
import NextNProgress from 'nextjs-progressbar';
import { MainLayout } from 'src/app/components/layout/MainLayout/MainLayout';
import { IHotelsRoomsAttributes } from 'src/models/hotels-rooms';

export type NextPageWithLayout<T> = NextPage<T> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout<T = {}> = AppProps & {
  Component: NextPageWithLayout<T>
}
interface ContentType {
  isFormShow: boolean
  setIsFormShow: Dispatch<SetStateAction<boolean>>
  editRoom: IHotelsRoomsAttributes | undefined
  setEditRoom: Dispatch<SetStateAction<IHotelsRoomsAttributes | undefined>>
}

export const RoomContext = createContext<ContentType>({} as any)

export function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => <MainLayout typeHeader={"main"}>{page}</MainLayout>)
  const queryClient = new QueryClient(queryClientConfig)
  const [isFormShow, setIsFormShow] = useState<boolean>(false);
  const [editRoom, setEditRoom] = useState<IHotelsRoomsAttributes | undefined>(undefined);

  return <>
    <NextNProgress color='#00D1FF' height={4} />
    <QueryClientProvider client={queryClient}>
      <ConfigProvider locale={ruRu}>
        <RoomContext.Provider value={{ isFormShow, setIsFormShow, editRoom, setEditRoom }}>
          {getLayout(<Component {...pageProps} />)}
        </RoomContext.Provider>
      </ConfigProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </>;
}

export default storeWrapper.withRedux(MyApp);
